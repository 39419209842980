import React, { FunctionComponent } from 'react'
import Loading from '../../components/Loading'
import ProductList from './ProductList'
import LoadingFail from '../../components/LoadingFail'
import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'

interface CoupangResultProps {
  isFetching: Boolean
  isError: Boolean
  result: any
}

const CoupangResult: FunctionComponent<CoupangResultProps> = ({
  isFetching,
  isError,
  result
}) => {
  if (isFetching) {
    return <Loading tipText="쿠팡 데이터를 불러오는 중입니다." />
  }

  if (isError) {
    return (
      <LoadingFail
        failMessage={
          <Space direction="vertical">
            쿠팡 데이터를 불러오는데 실패했습니다. 쿠팡키가 올바른지 확인해
            보세요.
            <Link to="/my">
              <Button type="primary">쿠팡 키 확인</Button>
            </Link>
          </Space>
        }
      />
    )
  }

  return <ProductList data={result} />
}

export default CoupangResult

import React, { Dispatch, useContext, useReducer } from 'react'
import { Action, AppState, initialState, reducer } from './reducer'

const AppContext = React.createContext<AppState | undefined>(undefined)
const AppDispatchContext = React.createContext<Dispatch<Action> | undefined>(
  undefined
)

export function AppContextProvider({
  children
}: {
  children: React.ReactNode
}) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export function useAppState() {
  const state = useContext(AppContext)
  if (!state) throw new Error('AppContext Provider not found')
  return state
}

export function useAppDispatch() {
  const dispatch = useContext(AppDispatchContext)
  if (!dispatch) throw new Error('AppDispatchContext Provider not found')
  return dispatch
}

import React, { FunctionComponent } from 'react'
import { Col, Row } from 'antd'

interface PageHeaderProps {
  title: string
  buttonGroup?: React.ReactNode
  description?: string
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({ title, buttonGroup, description }) => (
  <div>
    <Row>
      <Col flex="none">
        <h1>{title}</h1>
      </Col>
      <Col flex="auto"></Col>
      {buttonGroup && <Col flex="none">{buttonGroup}</Col>}
    </Row>
    {description && <small className="text-gray-500">{description}</small>}
  </div>
)

export default PageHeader

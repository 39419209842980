/**
 * isUndefinedOrNull(null) // true,
 * isUndefinedOrNull(undefined) // true
 * isUndefinedOrNull('null') // true
 */
export function isUndefinedOrNull(char?: string | null) {
  return char === null || char === undefined || char === 'null'
}


/**
 * checkNotEmpty('a') // true
 * checkNotEmpty('') // false
 * checkNotEmpty(null) // false
 */
export function checkNotEmpty(char?: string | null | undefined): boolean {
  return !isUndefinedOrNull(char) && char !== ''
}

export function checkEmpty(char: string | null | undefined): boolean {
  return !checkNotEmpty(char)
}

export function checkEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return emailRegex.test(email)
}

export function checkPhoneNumber(phone: string) {
  const phoneRegex = /^0([0-9]{1,2})-?([0-9]{3,4})-?([0-9]{4})$/
  return phoneRegex.test(phone)
}

export function checkName(name: string) {
  const nameRegex = /^[a-zA-Z가-힣]{2,10}$/
  return nameRegex.test(name)
}

/**
 * http://www.faqs.org/rfcs/rfc3987.html
 * https://code-examples.net/ko/q/277ca
 * https://mathiasbynens.be/notes/javascript-unicode
 * @param url {string}
 * @return {boolean}
 */
export function checkUrl(url: string) {
  // const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  const urlRegex = /^(http:\/\/|https:\/\/)/
  return urlRegex.test(url)
}

export function checkSecureUrl(url: string) {
  const urlRegex = /^(https:\/\/)/
  return urlRegex.test(url)
}

export function checkDateFormatWithHyphen(input: string) {
  const dateFormatRegex = /^\d{4}-\d{1,2}-\d{1,2}$/
  return dateFormatRegex.test(input)
}

export function checkDateFormatWithDot(input: string) {
  const dateFormatRegex = /^\d{4}.\d{1,2}.\d{1,2}$/
  return dateFormatRegex.test(input)
}


export function numberWithCommas(x: number) {
  return x.toLocaleString()
}

import React, { FunctionComponent } from 'react'
import { Spin } from 'antd'

interface LoadingProps {
  tipText?: string
}

const Loading: FunctionComponent<LoadingProps> = ({ tipText }) => {
  return (
    <div className="text-center p-20">
      <Spin size="large" tip={tipText} />
    </div>
  )
}

export default Loading

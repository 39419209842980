import { CoupangProduct } from '../types/coupang'
import { numberWithCommas } from './stringUtils'

function productsToHtml(product: CoupangProduct, subId: string) {
  // prettier-ignore
  return `<div class="content">
        <div class="item">
            <div class="item-image">
                <img src="${product.productImage}"/>
            </div>

            <div class="item-info">
                <div class="item-name">
                    <h1>${product.productName}</h1>
                </div>
                <div class="item-price">
                    <h2>${numberWithCommas(product.productPrice)}원</h2>
                    <div class="rocket-image">
                        <img class="${product.isRocket ? '' : ' hidden'}" src="//image10.coupangcdn.com/image/badges/rocket/rocket_logo.png" alt="로켓배송" height="16px"/>
                    </div>
                </div>
                <div class="item-buttons">
                    <a href="${product.productUrl}&subId=${subId}" class="button">최저가 보기</a>
                    <a href="${product.productUrl}&subId=${subId}" class="button">상품 리뷰 보기</a>
                </div>
                <div class="item-description">
                    <small>👉 상품의 가격, 상세정보는 변경될 수 있습니다.</small>
                    <small>👉 상단 최저가보기 버튼을 클릭 후 확인해주세요.</small>
                    <small>👉 파트너스 활동을 통해 일정액의 수수료를 제공받을 수 있습니다.</small>
                </div>
            </div>
        </div>`
}

function messageToHtml(message: string) {
  if (!message) return ''
  const paragraphs = message
    .split('\n')
    .map(line => `<p>${line}</p>`)
    .join('')

  return `<div class="message">${paragraphs}</div>`
}

function buildFinalHtml(headerMessage: string, footerMessage: string, product: CoupangProduct, subId: string) {
  const headerMessageHtml = messageToHtml(headerMessage)
  const footerMessageHtml = messageToHtml(footerMessage)
  const productsHtml = productsToHtml(product, subId)
  const contentHtml = [headerMessageHtml, productsHtml, footerMessageHtml].join('')
  return `<div id="cps_container">${contentHtml}</div>`
}

export { productsToHtml, messageToHtml, buildFinalHtml }

import React from 'react'
import { Button, Divider, Form, Input, message, Typography } from 'antd'
import { useMutation } from 'react-query'
import Loading from '../../components/Loading'
import { modifyAccount } from '../../api/accountApi'
import { UserInfo } from '../../types/user'
import { removeAuthInfo } from '../../utils/authUtils'
import { useHistory } from 'react-router-dom'
import PageHeader from '../../components/PageHeader'
import { QueryResponse } from '../../types/common'
import { useMyAccount } from '../../hooks/queries/account'

const { Title } = Typography

const Setting = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { data, isFetching } = useMyAccount()
  const { result } = data || {}
  const { mutate } = useMutation<QueryResponse, unknown, UserInfo>(modifyAccount)

  const handleSubmit = async (values: any) => {
    const { id, email, coupangAccessKey, coupangSecretKey } = values
    try {
      await mutate(
        {
          id,
          email,
          coupangAccessKey,
          coupangSecretKey
        },
        {
          onSuccess: () => {
            message.success(`사용자 정보 수정 완료`)
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  const logout = () => {
    removeAuthInfo()
    history.replace('/login')
  }

  return (
    <div>
      <PageHeader title="설정" description="내 정보를 수정합니다." />
      <Divider />
      <div>
        <Title level={4}>내 정보</Title>
        {isFetching ? (
          <Loading tipText="내 정보를 불러오는 중입니다." />
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              id: result?.id,
              email: result?.email,
              password: result?.password,
              coupangAccessKey: result?.coupangAccessKey,
              coupangSecretKey: result?.coupangSecretKey
            }}>
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="E-Mail">
              <Input placeholder="아이디를 입력하세요." />
            </Form.Item>
            {/*<Form.Item name="password" label="패스워드">*/}
            {/*  <Input placeholder="패스워드를 입력하세요." />*/}
            {/*</Form.Item>*/}
            <Divider />
            <Title level={4}>쿠팡 정보</Title>
            <Form.Item name="coupangAccessKey" label="Access Key">
              <Input placeholder="쿠팡 파트너스에서 제공한 액세스키를 입력해주세요." />
            </Form.Item>
            <Form.Item name="coupangSecretKey" label="Secret Key">
              <Input placeholder="쿠팡 파트너스에서 제공한 시크릿키를 입력해주세요." />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                확인
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  )
}

export default Setting

import React, { useEffect, useState } from 'react'
import { Button, message, Popconfirm, Table } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import queryString from 'query-string'
import { createChannel } from '../../api/channelApi'
import { getAccessToken } from '../../api/tistoryApi'
import { useFetchGet } from '../../hooks/useFetchGet'
import Loading from '../../components/Loading'
import { TistoryBlogInfo } from '../../types/post'
import { ChannelCreateRequest } from '../../types/channel'
import { tistoryAuthUrl } from '../../utils/TistoryConstants'
import { errorNotification } from '../../utils/toastUtils'
import { QueryResponse } from '../../types/common'
import { checkNotEmpty } from '../../utils/stringUtils'
import PageHeader from '../../components/PageHeader'

const TistoryAdd = () => {
  const location = useLocation()
  const history = useHistory()
  const [accessToken, setAccessToken] = useState<string>('')
  const [selectedTistoryBlog, setSelectedTistoryBlog] = useState<TistoryBlogInfo>()
  const { code } = queryString.parse(location.search, { arrayFormat: 'comma' })
  const codeString = code as string

  const { data: accessTokenData, isFetching: isFetchingAccessToken } = useQuery<void | QueryResponse>(
    'tistoryAccessToken',
    () => {
      return getAccessToken(codeString).catch(error => {
        const { message } = error.response.data
        if (message === 'invalid_request') {
          errorNotification('티스토리 액세스토큰 발급 중 오류가 발생했습니다. 3초 후 다시 시도합니다.')
          setTimeout(() => {
            window.location.href = tistoryAuthUrl
          }, 3000)
        }
      })
    },
    {
      enabled: checkNotEmpty(codeString)
    }
  )

  useEffect(() => {
    if (!isFetchingAccessToken && accessTokenData) {
      const { access_token } = accessTokenData.result
      setAccessToken(access_token)
    }
  }, [accessTokenData, isFetchingAccessToken])

  const { data: tistoryBlogData, isFetching: isFetchingTistoryBlogData } = useFetchGet(
    'tistoryBlogs',
    `/tistory/blogs?accessToken=${accessToken}`,
    {
      enabled: checkNotEmpty(accessToken)
    }
  )

  const { mutate } = useMutation(createChannel)

  const confirmAddChannel = async () => {
    if (selectedTistoryBlog === undefined) {
      message.error('채널로 추가할 티스토리 블로그를 선택해주세요.')
      return
    }

    const channelData: ChannelCreateRequest = {
      name: selectedTistoryBlog.name,
      description: selectedTistoryBlog?.description,
      accessToken: accessToken,
      type: 'TISTORY'
    }

    try {
      await mutate(channelData, {
        onSuccess: () => {
          message.success('채널 등록 완료')
          history.replace('/channels')
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      title: '블로그 이름',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '블로그 제목',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url'
    }
  ]

  const rowSelection = {
    onSelect: (record: TistoryBlogInfo) => {
      setSelectedTistoryBlog(record)
    }
  }

  return (
    <>
      <PageHeader title="티스토리 채널 추가" description="채널로 추가할 티스토리 블로그를 선택해주세요." />
      {accessToken && isFetchingTistoryBlogData ? (
        <Loading tipText="티스토리 블로그 정보를 불러오는 중입니다." />
      ) : (
        accessToken && (
          <>
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection
              }}
              columns={columns}
              dataSource={tistoryBlogData?.result.item.blogs}
              rowKey="name"
              bordered={true}
            />
            <Popconfirm
              title={`${selectedTistoryBlog?.name} 티스토리 블로그를 채널로 추가하시겠습니까?`}
              onConfirm={confirmAddChannel}
              okText="예"
              cancelText="아니오">
              <Button type="primary">확인</Button>
            </Popconfirm>
          </>
        )
      )}
    </>
  )
}

export default TistoryAdd

import React, { useState } from 'react'
import Loading from './Loading'
import { Input, Table } from 'antd'
import { Template } from '../types/template'
import { useFetchGet } from '../hooks/useFetchGet'
import { templateToText } from '../utils/templateUtils'

const { TextArea } = Input

interface TemplateListProps {
  handleClickTemplate: Function
}

const TemplateSelector = ({ handleClickTemplate }: TemplateListProps) => {
  const { data, isFetching } = useFetchGet('templates', '/templates')

  const [templateText, setTemplateText] = useState<string>('')

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: 20
    },
    {
      title: '템플릿 이름',
      dataIndex: 'name'
    }
  ]

  return (
    <div>
      {isFetching && <Loading tipText="템플릿을 불러오는 중입니다." />}
      {!isFetching && (
        <div>
          <Table<Template>
            rowKey="id"
            rowSelection={{
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => {
                const template = selectedRows[0]
                handleClickTemplate(template)
                setTemplateText(templateToText(template))
              }
            }}
            size="small"
            columns={columns}
            dataSource={data?.result}
            pagination={{ pageSize: 5 }}
          />
          <TextArea value={templateText} rows={8} readOnly></TextArea>
        </div>
      )}
    </div>
  )
}

export default TemplateSelector

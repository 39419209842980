import React from 'react'
import { Button, Descriptions, Divider, Popconfirm, Space, Table, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { deleteChannel } from '../../api/channelApi'
import Loading from '../../components/Loading'
import { Channel } from '../../types/channel'
import { tistoryAuthUrl } from '../../utils/TistoryConstants'
import PageHeader from '../../components/PageHeader'
import { useChannelList } from '../../hooks/queries/channel'
import { QueryResponse } from '../../types/common'

const ChannelList = () => {
  const history = useHistory()
  const { data, isFetching, refetch } = useChannelList()
  const [messageApi, contextHolder] = message.useMessage()

  const { mutate } = useMutation<QueryResponse, unknown, number>(deleteChannel)

  const confirmDelete = (id: number) => async () => {
    try {
      await mutate(id, {
        onSuccess: () => {
          messageApi.success('채널 삭제 완료')
          refetch()
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const moveModifyPage = (channelId: number) => () => {
    history.push(`/channels/${channelId}`)
  }

  const handleRegisterChannel = () => {
    messageApi.warning('현재는 티스토리 밖에 지원하지 않습니다.')
  }

  const columns = [
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 20
    // },
    // {
    //   title: '채널 타입',
    //   dataIndex: 'type',
    //   key: 'type'
    // },
    {
      title: '채널 정보',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Channel) => (
        <Descriptions size="small" title={record.name}>
          {record.description && <Descriptions.Item>{record.description}</Descriptions.Item>}
        </Descriptions>
      )
    },
    {
      title: '액션',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: Channel) => (
        <Space size="small" direction="vertical">
          <Button type="link" onClick={moveModifyPage(record.id)}>
            수정
          </Button>
          <Popconfirm
            title="정말 이 채널을 삭제하시겠습니까?"
            onConfirm={confirmDelete(record.id)}
            okText="Yes"
            cancelText="No">
            <Button type="link">삭제</Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <div>
      {contextHolder}
      <PageHeader
        title="채널 관리"
        buttonGroup={
          <Button key="registerTistory" type="primary" htmlType="button" href={tistoryAuthUrl}>
            티스토리 계정 연동
          </Button>
        }
        description="포스팅 할 채널을 관리 합니다. 채널은 내 블로그나 웹사이트를 의미합니다."
      />
      <Divider />
      <div>
        {isFetching ? (
          <Loading tipText="채널 목록을 불러오는 중입니다." />
        ) : (
          <Table<Channel> columns={columns} pagination={false} dataSource={data?.result} rowKey="id" bordered={true} />
        )}
      </div>
    </div>
  )
}

export default ChannelList

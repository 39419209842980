import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Setting from './Setting'

const Settings: FC = () => {
  return (
    <Switch>
      <Route path="/settings" component={Setting} />
    </Switch>
  )
}

export default Settings

import { TistoryPostRequest } from '../types/post'
import { fetchGetData, fetchPost } from '../utils/fetchUtils'
import { QueryResponse } from '../types/common'

function writePost(post: TistoryPostRequest): Promise<QueryResponse> {
  return fetchPost(`/tistory/write`, post)
}

function getAccessToken(code: string): Promise<QueryResponse> {
  return fetchGetData(`/tistory/accessToken?code=${code}`)
}

export { writePost, getAccessToken }

import React, { useMemo, useState } from 'react'
import './index.css'
import { Button, Checkbox, Col, Divider, Form, Input, message, Modal, Result, Row, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { useAppDispatch, useAppState } from '../../AppContextProvider'
import { buildFinalHtml, messageToHtml, partnersMessage, productsToHtml } from '../../utils/htmlUtils'
import { numberWithCommas } from '../../utils/stringUtils'
import { warnNotification } from '../../utils/toastUtils'
import { useMutation } from 'react-query'
import { PostRequest } from '../../types/post'
import { Channel } from '../../types/channel'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { postTistory } from '../../api/postApi'
import Loading from '../../components/Loading'
import { QueryResponse } from '../../types/common'
import { useChannelList } from '../../hooks/queries/channel'

const { TextArea } = Input
const { Title } = Typography

const BasketPosting = () => {
  const { basket } = useAppState()
  const dispatch = useAppDispatch()

  const { title, headerMessage, products, footerMessage } = basket
  const finalHtml = useMemo(() => {
    const headerMessageHtml = messageToHtml(headerMessage)
    const footerMessageHtml = messageToHtml(footerMessage)
    const productsHtml = productsToHtml(products, '') // 보여줄땐 그냥 subId 없이 보여줌
    const partnersMessageHtml = partnersMessage()
    const contentHtml = [headerMessageHtml, productsHtml, footerMessageHtml, partnersMessageHtml].join('')
    return `<div id="cps_container">${contentHtml}</div>`
  }, [products, headerMessage, footerMessage])

  const [form] = Form.useForm()
  const [isVisibleModal, setVisibleModal] = useState(false)

  const [selectedChannelIds, setSelectedChannelIds] = useState<CheckboxValueType[]>([])

  const showHtml = () => {
    if (products.length === 0) {
      warnNotification('먼저 상품을 담아주세요.')
      return
    }
    setVisibleModal(true)
  }

  const handleDeleteProduct = (basketCoupangProductId: string) => () => {
    dispatch({ type: 'DELETE_PRODUCT_IN_BASKET', payload: { basketCoupangProductId } })
  }

  const { data: channelData, isFetching: isChannelFetching } = useChannelList()

  const { mutate } = useMutation<QueryResponse, unknown, PostRequest>(postTistory)

  const handleCheckChannel = (checkedChannelIds: CheckboxValueType[]) => {
    setSelectedChannelIds(checkedChannelIds)
  }

  const handlePost = async (values: any) => {
    const post = async (postRequest: PostRequest) => {
      try {
        await mutate(postRequest, {
          onSuccess: () => {
            message.success('포스팅 완료')
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    const { channels } = values
    channels.forEach((id: number) => {
      const channel = channelData?.result.find((channel: Channel) => channel.id === id)
      const finalHtml = buildFinalHtml(headerMessage, footerMessage, products, channel.name)
      const postRequest: PostRequest = {
        channelId: id,
        title,
        content: finalHtml,
        products: products
      }

      post(postRequest)
    })
  }

  return (
    <div>
      <div className="flex justify-between my-4 content-center items-center">
        <div>
          <h1 className="mb-0">미리보기</h1>
          <small className="text-gray-500">
            상품 바구니에 담긴 상품들을 포스팅했을때의 모습을 미리 본 후에 포스팅 합니다.
          </small>
        </div>
        <div>
          <Space size="small">
            <Button key="showHtml" type="primary" htmlType="button" onClick={showHtml}>
              HTML 보기
            </Button>
          </Space>
        </div>
      </div>
      <Divider />
      <div>
        {products.length === 0 && (
          <Result
            title="바구니에 담긴 쿠팡 상품이 없습니다."
            extra={
              <Link to="/products/search">
                <Button type="primary" key="search">
                  검색하러 가기
                </Button>
              </Link>
            }
          />
        )}
        {products.length > 0 && (
          <Form form={form} name="post" onFinish={handlePost} initialValues={{ title, headerMessage, footerMessage }}>
            <Row gutter={16}>
              <Col sm={24} md={18}>
                <Form.Item name="title">
                  <Input readOnly={true} bordered={false} style={{ fontSize: '2em' }} />
                </Form.Item>
                <Form.Item name="headerMessage">
                  <TextArea rows={headerMessage.split('\n').length} bordered={false} />
                </Form.Item>
                <div id="cps_container" className="bg-white">
                  <div className="p_cont">
                    <ul>
                      {products.map((product, i) => {
                        return (
                          <li key={`${product.id}`}>
                            <div className="item-container">
                              <a
                                href={product.productUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={e => e.preventDefault()}>
                                <div className="item">
                                  <div className="ranking">{i + 1}</div>
                                  <div className="delete">
                                    <Button size="small" onClick={handleDeleteProduct(product.id)}>
                                      삭제
                                    </Button>
                                  </div>
                                  <div className="item-image">
                                    <img src={product.productImage} alt={product.productName} />
                                  </div>
                                  <div className="rocket-image">
                                    <img
                                      src="//image10.coupangcdn.com/image/badges/rocket/rocket_logo.png"
                                      height="16"
                                      alt="로켓배송"
                                      className={cx({
                                        hidden: !product.isRocket
                                      })}
                                    />
                                  </div>
                                  <div className="item-name">{product.productName}</div>
                                  <div className="item-price">{numberWithCommas(product.productPrice)}원</div>
                                </div>
                              </a>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <Form.Item name="footerMessage">
                  <TextArea rows={footerMessage.split('\n').length} bordered={false} />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Title level={5}>채널</Title>
                {isChannelFetching && <Loading tipText="채널을 불러오는 중입니다." />}
                {!isChannelFetching && (
                  <Form.Item name="channels">
                    <Checkbox.Group onChange={handleCheckChannel}>
                      <Space direction="vertical">
                        {channelData?.result.map((channel: Channel) => (
                          <Checkbox
                            key={`channel_${channel.id}`}
                            value={channel.id}
                            checked={selectedChannelIds.includes(channel.id)}>
                            {channel.name} ({channel.type})
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                )}
                <Button type="primary" htmlType="submit" block={true}>
                  포스팅
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>

      <Modal
        open={isVisibleModal}
        title="Html 보기"
        width={1000}
        onOk={handlePost}
        onCancel={() => setVisibleModal(visible => !visible)}
        footer={[
          <Button key="close" onClick={() => setVisibleModal(visible => !visible)}>
            닫기
          </Button>
        ]}>
        <TextArea value={finalHtml} autoSize={{ minRows: 15 }} />
      </Modal>
    </div>
  )
}

export default BasketPosting

const Preview = () => {}

import React, { useState } from 'react'
import { Divider, Tag } from 'antd'
import { useFetchGet } from '../../hooks/useFetchGet'
import CoupangResult from './CoupangResult'
import usePrevious from '../../hooks/usePrevious'

const Categories = [
  { categoryId: '1001', text: '여성패션' },
  { categoryId: '1002', text: '남성패션' },
  { categoryId: '1010', text: '뷰티' },
  { categoryId: '1011', text: '출산/유아동' },
  { categoryId: '1012', text: '식품' },
  { categoryId: '1013', text: '주방용품' },
  { categoryId: '1014', text: '생활용품' },
  { categoryId: '1015', text: '홈인테리어' },
  { categoryId: '1016', text: '가전디지털' },
  { categoryId: '1017', text: '스포츠/레저' },
  { categoryId: '1018', text: '자동차용품' },
  { categoryId: '1019', text: '도서/음반/DVD' },
  { categoryId: '1020', text: '완구/취미' },
  { categoryId: '1021', text: '문구/오피스' },
  { categoryId: '1024', text: '헬스/건강식품' },
  { categoryId: '1025', text: '국내여행' },
  { categoryId: '1026', text: '해외여행' },
  { categoryId: '1029', text: '반려동물용품' },
  { categoryId: '1030', text: '유아동패션' }
]

const BestCategories = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('1001')
  const previousCategoryId = usePrevious(selectedCategoryId)

  const { data, isFetching, isError } = useFetchGet(
    ['bestCategories', selectedCategoryId],
    `/coupang/bestcategories/${selectedCategoryId}`,
    {
      enabled: previousCategoryId != selectedCategoryId
    }
  )

  const handleClickCategory = (categoryId: string) => () => {
    setSelectedCategoryId(categoryId)
  }

  return (
    <div>
      <div>
        {Categories.map(category => {
          const { categoryId, text } = category

          return (
            <Tag.CheckableTag
              key={categoryId}
              onClick={handleClickCategory(categoryId)}
              checked={categoryId === selectedCategoryId}>
              {text}
            </Tag.CheckableTag>
          )
        })}
      </div>
      <Divider />
      <CoupangResult isFetching={isFetching} isError={isError} result={data?.result} />
    </div>
  )
}

export default BestCategories

import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Form, Input, message, Popconfirm, Result, Row } from 'antd'
import { UserInfo } from './types/user'
import { modifyAccount } from './api/accountApi'
import { useHistory } from 'react-router-dom'
import { useAppState } from './AppContextProvider'
import { QueryResponse } from './types/common'

const SetCoupangKey = () => {
  const history = useHistory()
  const { user } = useAppState()
  const { mutate } = useMutation<QueryResponse, unknown, UserInfo>(modifyAccount)

  useEffect(() => {
    if (user.id === -1) {
      history.replace('/signup')
    }
  }, [user, history])

  const onFinish = async (values: UserInfo) => {
    const { id, email } = user
    const { coupangAccessKey, coupangSecretKey } = values
    try {
      await mutate(
        {
          id,
          email,
          coupangAccessKey,
          coupangSecretKey
        },
        {
          onSuccess: () => {
            message.success(`쿠팡 키 설정 완료`)
            history.replace('/')
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Result
        status="success"
        title="Coupas 회원 가입 완료"
        subTitle={
          <div>
            쿠파스 회원이 되신것을 진심으로 환영합니다.
            <br />
            쿠파스를 정상적으로 이용하시려면 쿠팡에서 발급한 AccessKey, SecretKey 입력이 필요합니다.
          </div>
        }
      />
      <Row>
        <Col span={24}>
          <div className="mx-auto">
            <Form style={{ margin: '0 auto' }} layout="vertical" className="login-form" onFinish={onFinish}>
              <Form.Item
                name="coupangAccessKey"
                label="쿠팡 AccessKey"
                rules={[
                  {
                    required: true,
                    message: '쿠팡 AccessKey 를 입력해주세요.'
                  }
                ]}>
                <Input placeholder="쿠팡 AccessKey" />
              </Form.Item>
              <Form.Item
                name="coupangSecretKey"
                label="쿠팡 SecretKey"
                rules={[
                  {
                    required: true,
                    message: '쿠팡 SecretKey 를 입력해주세요.'
                  }
                ]}>
                <Input placeholder="쿠팡 SecretKey" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  확인
                </Button>
              </Form.Item>
              <Popconfirm
                title={
                  <span>
                    쿠팡키를 입력하지 않고 넘어가시겠습니까?
                    <br />
                    쿠팡키를 입력하지 않으면 쿠파스를 정상적으로 이용할 수 없습니다.
                  </span>
                }
                onConfirm={() => history.push('/')}
                okText="Yes"
                cancelText="No">
                <Button block>건너뛰기</Button>
              </Popconfirm>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SetCoupangKey

import { fetchGetData, fetchPostNoToken, fetchPut } from '../utils/fetchUtils'
import { LoginInfo, UserInfo } from '../types/user'
import { QueryResponse } from '../types/common'

function getMyAccount(): Promise<QueryResponse> {
  return fetchGetData(`/accounts/me`)
}

function modifyAccount(userBody: UserInfo): Promise<QueryResponse> {
  return fetchPut(`/accounts/${userBody.id}`, userBody)
}

function authenticate(loginInfo: LoginInfo): Promise<QueryResponse> {
  return fetchPostNoToken('/auth', null, loginInfo)
}

function signUp(loginInfo: LoginInfo): Promise<QueryResponse> {
  return fetchPostNoToken('/accounts/signup', loginInfo)
}

export { getMyAccount, modifyAccount, authenticate, signUp }

import React from 'react'
import { Input } from 'antd'
import { useHistory } from 'react-router-dom'

const { Search } = Input

const SearchInput = () => {
  const history = useHistory()

  const onSearch = (values: string, event: any) => {
    if (values === '') return
    history.push('/search', { query: values })
  }

  return (
    <span className="search-area">
      <Search
        placeholder="검색어를 입력하세요."
        onSearch={onSearch}
        style={{ verticalAlign: 'middle' }}
        allowClear
      />
    </span>
  )
}

export default SearchInput

import React, { useState } from 'react'
import { Pagination, PaginationProps } from 'antd'
import CoupangResult from '../Product/CoupangResult'
import { useFetchGet } from '../../hooks/useFetchGet'
import usePrevious from '../../hooks/usePrevious'

type RecentProductsProps = {}

const RecentProducts = ({}: RecentProductsProps) => {
  const [page, setPage] = useState(1)
  const previousPage = usePrevious(page)

  const { data, isFetching, isError } = useFetchGet(
    ['products', 'recent'],
    `/browse/products/recent?page=${page}&size=8`,
    {
      enabled: previousPage != page
    }
  )

  const onChangePage: PaginationProps['onChange'] = page => {
    console.log(page)
    setPage(page)
  }

  return (
    <div>
      <CoupangResult isFetching={isFetching} isError={isError} result={data?.result.content} />
      {!isFetching && (
        <Pagination
          current={page}
          onChange={onChangePage}
          total={data?.result.totalElements}
          pageSize={data?.result.size}
        />
      )}
    </div>
  )
}

export default RecentProducts

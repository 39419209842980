import { fetchDelete, fetchGetData, fetchPost, fetchPut } from '../utils/fetchUtils'
import { ChannelCreateRequest } from '../types/channel'
import { QueryResponse } from '../types/common'

function getChannels(): Promise<QueryResponse> {
  return fetchGetData('/channels')
}

function getChannel(id: string): Promise<QueryResponse> {
  return fetchGetData(`/channels/${id}`)
}

function createChannel(channelBody: ChannelCreateRequest) {
  return fetchPost(`/channels`, channelBody)
}

function modifyChannel(channelBody: ChannelCreateRequest) {
  return fetchPut(`/channels/${channelBody.id}`, channelBody)
}

function deleteChannel(id: number) {
  return fetchDelete(`/channels/${id}`)
}

export { getChannels, getChannel, createChannel, modifyChannel, deleteChannel }

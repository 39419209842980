import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Divider, Form, Input, Popconfirm, Table } from 'antd'
import { createDndContext, DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import update from 'immutability-helper'
import { FormInstance } from 'antd/es/form'

import { modifyTemplate, postTemplate } from '../../api/templateApi'
import { successNotification } from '../../utils/toastUtils'
import Loading from '../../components/Loading'
import PageHeader from '../../components/PageHeader'
import { Template, TemplateContent } from '../../types/template'
import { QueryResponse } from '../../types/common'
import './index.css'

interface RouteParams {
  templateId: string
}

// RowProps reference: https://codesandbox.io/s/react-typescript-forked-ogj8r?file=/src/index.tsx:497-561
interface RowProps {
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
}

type MyRowProps = RowProps & React.HTMLAttributes<HTMLElement>

// TODO : sortable table - https://codesandbox.io/s/vh5xs?file=/index.js:304-314

const RNDContext = createDndContext(HTML5Backend)
const type = 'DragableBodyRow'

const EditableContext = React.createContext<any>(undefined)

const DragEditableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
  const ref = React.useRef()
  const [form] = Form.useForm()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: (item: any) => {
      moveRow(item.index, index)
    }
  })

  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drop(drag(ref))

  return (
    <Form key={index} form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr
          ref={ref}
          className={`${className}${isOver ? dropClassName : ''}`}
          style={{ cursor: 'move', ...style }}
          {...restProps}
        />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  rowIndex,
  record,
  title,
  editable,
  children,
  dataIndex,
  handleSave,
  editTemplateContent,
  ...restProps
}: any) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>()
  const form = useContext<FormInstance>(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const save = async (e: SyntheticEvent) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      const newRowData = { ...record, ...values }
      editTemplateContent(rowIndex, newRowData)
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

const TemplateDetail = () => {
  const [dataSource, setDataSource] = useState<TemplateContent[]>([])
  const [form] = Form.useForm()
  const history = useHistory()
  const { templateId } = useParams<RouteParams>()
  const isModify = templateId !== undefined
  const registerText = isModify ? '수정' : '등록'

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      className: 'hidden'
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'templateContent',
      editable: true
    },
    {
      title: 'userId',
      dataIndex: ['user', 'id'],
      // key: ['user', 'id'],
      className: 'hidden'
    },
    {
      title: '액션',
      dataIndex: 'operation',
      render: (text: string, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="정말 삭제하시겠습니까?" onConfirm={() => handleDelete(record.id)}>
            <Button type="link">삭제</Button>
          </Popconfirm>
        ) : null
    }
  ]

  const { data, isFetching } = useFetchGet(['templates', templateId], `/templates/${templateId}`, {
    enabled: isModify
  })

  const { mutate } = useMutation<QueryResponse, unknown, Template>(
    templateId === undefined ? postTemplate : modifyTemplate
  )

  useEffect(() => {
    if (isModify && !isFetching) {
      const { result } = data || { results: {} }
      form.setFieldsValue({
        id: result.id,
        name: result.name
      })
      setDataSource(result.templateContents)
    }
  }, [isModify, isFetching, form, data])

  const handleDelete = (id: number) => {
    setDataSource(dataSource.filter(item => item.id !== id))
  }

  const handleAdd = () => {
    const newData: TemplateContent = {
      content: '내용을 수정해주세요.'
    }

    setDataSource([...dataSource, newData])
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex]
      setDataSource(
        update(dataSource, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [dataSource]
  )

  const manager = useRef(RNDContext)

  const editTemplateContent = (rowIndex: number, templateContent: TemplateContent) => {
    const newDataSource = [...dataSource]
    newDataSource.splice(rowIndex, 1, templateContent)

    setDataSource(newDataSource)
  }

  const components = {
    body: {
      row: DragEditableBodyRow,
      cell: EditableCell
    }
  }

  const editableColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: any, rowIndex: number) => ({
        rowIndex,
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editTemplateContent: editTemplateContent
      })
    }
  })

  const handleSaveTemplate = async (values: any) => {
    const template: Template = {
      ...values,
      templateContents: dataSource
    }
    try {
      await mutate(template, {
        onSuccess: () => {
          successNotification(`템플릿 ${registerText} 성공`)
          history.replace('/templates')
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <PageHeader title={`템플릿 ${registerText}`} description={`반복적으로 사용할 템플릿을 ${registerText}합니다.`} />
      <Divider />
      <div>
        {isFetching ? (
          <Loading tipText="템플릿을 불러 오는 중 입니다." />
        ) : (
          <Form form={form} layout="vertical" onFinish={handleSaveTemplate}>
            <Form.Item name="id" hidden>
              <Input hidden />
            </Form.Item>
            <Form.Item name="name" label="템플릿 이름" required>
              <Input placeholder="템플릿 이름을 입력해주세요." />
            </Form.Item>

            <Button
              onClick={handleAdd}
              style={{
                marginBottom: 16
              }}>
              템플릿 추가
            </Button>
            <DndProvider manager={manager.current.dragDropManager!}>
              <Table<TemplateContent>
                rowKey="id"
                components={components}
                rowClassName={() => 'editable-row'}
                pagination={false}
                dataSource={dataSource}
                columns={editableColumns}
                onRow={(record, index) =>
                  ({
                    index,
                    moveRow
                  } as MyRowProps)
                }
                bordered={true}
              />
            </DndProvider>
            <div className="mt-4">
              <Button type="primary" htmlType="submit">
                템플릿 {registerText}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  )
}

export default TemplateDetail

import React, { FunctionComponent } from 'react'
import { nanoid } from 'nanoid'
import { Badge, Button, Card, List } from 'antd'
import { CoupangProduct, ProductItemProps, ProductListProps } from '../../types/coupang'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppState } from '../../AppContextProvider'
import { EditOutlined, PlusCircleOutlined, ShoppingTwoTone } from '@ant-design/icons'
import PriceInfo from '../../components/PriceAndShipping'
import { infoNotification } from '../../utils/toastUtils'

const { Meta } = Card

const ProductItemCard: FunctionComponent<ProductItemProps> = ({ item }) => {
  const dispatch = useAppDispatch()
  const { basket } = useAppState()

  const putInBasket = (product: CoupangProduct) => () => {
    const hasSameProduct = basket.products.some(p => p.productId == product.productId)
    if (hasSameProduct) {
      infoNotification('바구니에 같은 상품이 담겨 있습니다. 다만 색상이 다를 수도 있습니다.')
    }
    dispatch({
      type: 'ADD_PRODUCT_IN_BASKET',
      payload: { id: nanoid(), ...product }
    })
  }

  return (
    <List.Item style={{ padding: '4px', marginBlockEnd: '4px' }}>
      <Card
        size="small"
        cover={
          <div className="overflow-hidden border-b text-center p-3">
            <a href={item.productUrl} target="_blank" rel="noopener noreferrer" className="inline-block">
              <img alt={item.productName} src={item.productImage} className="xs:h-36 xs:w-36 sm:w-44 sm:h-44" />
            </a>
          </div>
        }
        actions={[
          <PlusCircleOutlined key="add" onClick={putInBasket(item)} />,
          <Link
            to={{
              pathname: '/post',
              state: { coupangData: item }
            }}>
            <EditOutlined key="post" />
          </Link>
        ]}>
        <Meta
          title={
            <Link to={item.productUrl} target="_blank">
              <span className="text-sm overflow-visible">{item.productName}</span>
            </Link>
          }
          description={<PriceInfo item={item} />}
        />
      </Card>
    </List.Item>
  )
}

const ProductList: FunctionComponent<ProductListProps> = ({ data }) => {
  const { basket } = useAppState()

  return (
    <div>
      <List
        grid={{
          xs: 2,
          sm: 3,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4
        }}
        dataSource={data}
        renderItem={item => <ProductItemCard item={item} />}
      />
      <div className="basket">
        <Link to="/basket">
          <Button
            style={{ width: '64px', height: '64px' }}
            size="large"
            shape="circle"
            icon={<ShoppingTwoTone style={{ fontSize: '36px' }} twoToneColor="#ff0000" />}
          />
          <Badge count={basket.products.length} />
        </Link>
      </div>
    </div>
  )
}

export default ProductList

import axios, { Method } from 'axios'
import { getAuthToken } from './authUtils'
import { errorNotification } from './toastUtils'
import { checkNotEmpty } from './stringUtils'
import { QueryResponse } from '../types/common'

const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SEVER_ERROR: 500
}

export const DOMAIN = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port

// axios global config
axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.headers = {
  'Content-Type': 'application/json',
  Cache: 'no-cache'
}
axios.defaults.withCredentials = true

axios.interceptors.response.use(
  response => {
    const { authorization } = response.headers
    if (checkNotEmpty(authorization)) {
      console.log(authorization)
    }

    return response
  },
  e => {
    if (axios.isCancel(e)) {
      /*******************
       * Request canceled
       *******************/
      return Promise.reject(e)
    } else {
      if (e.response) {
        /**************************************
         * Server responded with a status code
         **************************************/
        const { status, data } = e.response
        const { message } = data

        switch (Number(status)) {
          case HTTP_STATUS_CODE.BAD_REQUEST:
          case HTTP_STATUS_CODE.CONFLICT: {
            errorNotification(message)
            console.log(e)
            break
          }

          case HTTP_STATUS_CODE.UNAUTHORIZED: {
            window.location.replace('/login')
            break
          }

          case HTTP_STATUS_CODE.FORBIDDEN: {
            window.location.replace('/forbidden')
            break
          }

          case HTTP_STATUS_CODE.NOT_FOUND: {
            window.location.replace('/notFound')
            break
          }

          case HTTP_STATUS_CODE.INTERNAL_SEVER_ERROR: {
            window.location.replace('/serverError')
            break
          }

          default: {
            window.location.replace('/serverError')
            return Promise.reject(e)
          }
        }
        return Promise.reject(e)
      } else {
        /******************************
         * Something happened
         * - CORS, Network error, etc.
         ******************************/

        if (e.request) {
          /**************************************
           * Requested, but no response received
           * - Gateway shutdown (502 Bad Gateway)
           **************************************/
        }
        window.location.replace('/serverError')

        return Promise.reject(e)
      }
    }
  }
)

function fetchGetNoToken(url: string) {
  return axios.get(url, {
    headers: {},
    responseType: 'json'
  })
}

function fetchPostNoToken(url: string, body?: any, headers?: any): Promise<QueryResponse> {
  const data = axios
    .post(url, body, {
      headers: headers || {},
      responseType: 'json'
    })
    .then(response => response.data)

  return Promise.resolve(data)
}

function fetchMethod(url: string, method: Method, body?: any, cancelTokenSource?: any): Promise<QueryResponse> {
  const headers = {
    Authorization: 'Bearer ' + getAuthToken()
  }

  const data = axios(url, {
    method,
    url: url,
    data: body,
    headers,
    cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
    withCredentials: true
  }).then(response => response.data)

  return Promise.resolve(data)
}

async function fetchMethodOnlyData(
  url: string,
  method: Method,
  body: any,
  cancelTokenSource: any
): Promise<QueryResponse> {
  const headers = {
    Authorization: 'Bearer ' + getAuthToken()
  }

  const data = axios(url, {
    method,
    url: url,
    data: body,
    headers,
    cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
    withCredentials: true
  }).then(response => response.data)

  return Promise.resolve<QueryResponse>(data)
}

function fetchGet(url: string, cancelTokenSource?: any) {
  return fetchMethod(url, 'get', {}, cancelTokenSource)
}

function fetchGetData(url: string, cancelTokenSource?: any) {
  return fetchMethodOnlyData(url, 'get', {}, cancelTokenSource)
}

function fetchPost(url: string, body: any, cancelTokenSource?: any) {
  return fetchMethod(url, 'post', body, cancelTokenSource)
}

function fetchPut(url: string, body: any) {
  return fetchMethod(url, 'put', body)
}

function fetchDelete(url: string) {
  return fetchMethod(url, 'delete')
}

function fetchPostForm(url: string, data: any, onProgress: Function, cancelTokenSource?: any) {
  const headers = {
    // Authorization: 'Token ' + getAuthToken(),
    'Content-Type': 'multipart/form-data'
  }

  return axios(url, {
    method: 'post',
    data,
    onUploadProgress: onUploadProgress(onProgress),
    cancelToken: cancelTokenSource?.token,
    headers
  })
}

const onUploadProgress = (onProgress: Function) => {
  if (typeof onProgress !== 'function') return

  const min = 0.001
  let startTime: number | null = null

  return (callback: any) => {
    const { timeStamp, loaded, total } = callback
    const progress = Math.max(loaded / total, min)
    const expectedRemainTime = (timeStamp - (startTime || timeStamp)) * (1 / progress - 1)
    onProgress(progress, expectedRemainTime)
  }
}
export { fetchGetNoToken, fetchPostNoToken, fetchGet, fetchGetData, fetchPost, fetchPut, fetchDelete, fetchPostForm }

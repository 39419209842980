import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

const Error403 = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={handleBack}>
          Go Back
        </Button>
      }
    />
  )
}

const Error404 = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleBack}>
          Go Back
        </Button>
      }
    />
  )
}

const Error = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={handleBack}>
          Go Back
        </Button>
      }
    />
  )
}

export { Error403, Error404, Error }

import React, { FunctionComponent } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import {
  LineChartOutlined,
  LogoutOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  SlidersOutlined,
  SnippetsOutlined,
  UserOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { removeAuthInfo } from '../utils/authUtils'

const items: MenuProps['items'] = [
  {
    label: '쿠팡 추천',
    key: 'coupang_recommend',
    icon: <ShoppingCartOutlined />
  },
  {
    type: 'divider'
  },
  {
    label: '리포트',
    key: 'report',
    icon: <LineChartOutlined />
  },
  {
    label: '채널',
    key: 'my-channel',
    icon: <SlidersOutlined />
  },
  {
    label: '템플릿',
    key: 'my-template',
    icon: <SnippetsOutlined />
  },
  {
    label: '설정',
    key: 'settings',
    icon: <UserOutlined />
  },
  {
    type: 'divider'
  },
  {
    label: '로그아웃',
    key: 'logout',
    icon: <LogoutOutlined />
  }
]

const DropdownMenuButton: FunctionComponent = () => {
  const history = useHistory()

  const logout = () => {
    removeAuthInfo()
    history.replace('/login')
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'coupang_recommend':
        history.push('/coupang_recommend')
        return
      case 'report':
        history.push('/reports')
        return
      case 'settings':
        history.push('/settings')
        return
      case 'my-channel':
        history.push('/channels')
        return
      case 'my-template':
        history.push('/templates')
        return
      case 'logout':
        logout()
        return
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick
  }

  return (
    <Dropdown menu={menuProps} placement="bottomRight" trigger={['click']}>
      <Button icon={<MenuOutlined />} />
    </Dropdown>
  )
}

export default DropdownMenuButton

import { useQuery } from 'react-query'
import QueryKey from './queryKey'
import { getChannel, getChannels } from '../../api/channelApi'

function useChannelList() {
  return useQuery(QueryKey.CHANNELS, getChannels)
}

function useChannel(id: string, options: any) {
  return useQuery(QueryKey.CHANNEL_ITEM, () => getChannel(id), { ...options })
}

export { useChannelList, useChannel }

import axios from 'axios'
import { useQuery } from 'react-query'
import { getAuthToken } from '../utils/authUtils'
import { QueryResponse } from '../types/common'

const fetchGet = async (url: string): Promise<QueryResponse> => {
  const token = getAuthToken()
  const { data } = await axios(url, {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    method: 'GET',
    url: url,
    withCredentials: true
  })
  return Promise.resolve<QueryResponse>(data)
}

function useFetchGet(queryKey: string | string[], url: string, option?: any) {
  return useQuery(queryKey, () => fetchGet(url), {
    ...option
  })
}

export { fetchGet, useFetchGet }

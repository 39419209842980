import React, { FunctionComponent, useState } from 'react'

import { Divider, Radio, RadioChangeEvent } from 'antd'
import BestCategories from '../Product/BestCategories'
import GoldBox from '../Product/GoldBox'
import CoupangPl from '../Product/CoupangPl'

interface HomeMenuProps {
  menu: string
  onChangeMenu?: (menu: string) => void
}
const HomeSubMenu: FunctionComponent<HomeMenuProps> = ({ menu, onChangeMenu }) => {
  const changeMenu = (e: RadioChangeEvent) => {
    const value = e.target.value
    onChangeMenu && onChangeMenu(value)
  }

  return (
    <Radio.Group value={menu} onChange={changeMenu}>
      <Radio.Button value="goldbox">골드박스</Radio.Button>
      <Radio.Button value="coupangpl">쿠팡 PL</Radio.Button>
      <Radio.Button value="bestcategories">베스트 카테고리</Radio.Button>
    </Radio.Group>
  )
}

const ProductComponent: FunctionComponent<HomeMenuProps> = ({ menu }): JSX.Element => {
  switch (menu) {
    case 'bestcategories':
      return <BestCategories />
    case 'goldbox':
      return <GoldBox />
    case 'coupangpl':
      return <CoupangPl />
    default:
      return <div></div>
  }
}

const CoupangRecommend: FunctionComponent = () => {
  const [menu, setMenu] = useState<string>('goldbox')

  return (
    <>
      <HomeSubMenu menu={menu} onChangeMenu={setMenu} />
      <Divider />
      <ProductComponent menu={menu} />
    </>
  )
}

export default CoupangRecommend

import React from 'react'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Button, Form, Input, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { LoginInfo } from './types/user'
import { authenticate } from './api/accountApi'

import { useAppDispatch } from './AppContextProvider'

const { Header, Content, Footer } = Layout

const Login = () => {
  const history = useHistory()
  const { from } = { from: { pathname: '/' } }

  const dispatch = useAppDispatch()
  const { mutate, isError, error } = useMutation(authenticate)

  const onSubmitAuth = async (values: LoginInfo) => {
    await mutate(values, {
      onSuccess: data => {
        // const { userId, email } = data.result
        // dispatch({ type: 'SET_ACCOUNT', payload: { id: userId, email } })
        message.success('로그인 성공')
        history.replace("/")
      },
      onError: (error, variables, context) => {
        alert('로그인 실패. 유저네임과 패스워드를 확인해주세요.')
      }
    })
  }

  return (
    <Layout className="site-layout h-screen">
      <Header className="site-layout-header mb-6">
        <div className="header-wrapper mx-auto xs:w-full text-center">
          <span className="font-extrabold text-xl text-black">Coupas</span>
        </div>
      </Header>
      <Content>
        <div className="login-form-wrapper">
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true
            }}
            onFinish={onSubmitAuth}>
            <Form.Item
              label="아이디"
              requiredMark="optional"
              name="username"
              rules={[
                {
                  required: true,
                  message: '이메일을 입력해주세요.'
                }
              ]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="abc@gmail.com"
              />
            </Form.Item>
            <Form.Item
              label="패스워드"
              requiredMark="optional"
              name="password"
              rules={[
                {
                  required: true,
                  message: '패스워드를 입력해주세요.'
                }
              ]}>
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="mb-1">
              <Button type="primary" size="large" htmlType="submit" block>
                로그인
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="w-1/3 p-4 mx-auto text-center">
          <span>
            <Link to="/signup">회원 가입</Link>
          </span>
        </div>
      </Content>
    </Layout>
  )
}

export default Login

import { Template, TemplateContent } from '../types/template'

function templateToText(template: Template) {
  const texts = template.templateContents.map(
    (c: TemplateContent) => `${c.content}\n`
  )
  return texts.join('')
}

export { templateToText }

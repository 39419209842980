import React, { useState } from 'react'
import { Divider, Tag } from 'antd'
import { useFetchGet } from '../../hooks/useFetchGet'
import CoupangResult from './CoupangResult'

const Brands = [
  { brandId: '1001', text: '탐사' },
  { brandId: '1002', text: '코멧' },
  { brandId: '1003', text: 'Gomgom' },
  { brandId: '1004', text: '줌' },
  { brandId: '1005', text: '마케마케' },
  { brandId: '1006', text: '곰곰' },
  { brandId: '1007', text: '꼬리별' },
  { brandId: '1008', text: '베이스알파에센셜' },
  { brandId: '1010', text: '비타할로' },
  { brandId: '1011', text: '비지엔젤' }
]

const CoupangPl = () => {
  const [selectedBrandId, setSelectedBrandId] = useState('1001')
  const { data, isFetching, isError } = useFetchGet(
    ['coupangPl', selectedBrandId],
    `/coupang/coupangPl/${selectedBrandId}`
  )

  const handleClickBrandId = (categoryId: string) => () => {
    setSelectedBrandId(categoryId)
  }

  return (
    <div>
      <div>
        {Brands.map(brand => {
          const { brandId, text } = brand

          return (
            <Tag.CheckableTag key={brandId} onClick={handleClickBrandId(brandId)} checked={brandId === selectedBrandId}>
              {text}
            </Tag.CheckableTag>
          )
        })}
      </div>
      <Divider />
      <CoupangResult isFetching={isFetching} isError={isError} result={data?.result} />
    </div>
  )
}

export default CoupangPl

import React, { FunctionComponent } from 'react'
import { ProductItemProps } from '../types/coupang'
import rocketLogo from '../assets/rocket_logo.png'

const ShippingIcon: FunctionComponent<ProductItemProps> = ({ item }) => {
  if (item.isRocket) {
    return (
      <img
        src={rocketLogo}
        alt="로켓배송"
        style={{ width: '52px', height: '14px' }}
      />
    )
  } else if (item.isFreeShipping) {
    return <span>무료배송</span>
  }

  return <span></span>
}

export default ShippingIcon
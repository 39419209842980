class QueryKey {
  public static readonly CHANNELS = 'channels'
  public static readonly CHANNEL_ITEM = 'channels_item'
  public static readonly PRODUCTS_RECENT = 'products_recent'
  public static readonly COUPANG_GOLDBOX = 'coupang_goldbox'
  public static readonly COUPANG_PL = 'coupang_pl'

  public static readonly ACCOUNT_MY = 'account_my'
}

export default QueryKey

import React from 'react'
import { useMutation } from 'react-query'
import { Button, Form, Input, message, Layout } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { LoginInfo } from './types/user'
import { signUp } from './api/accountApi'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from './AppContextProvider'

const { Header, Content, Footer } = Layout

const SignUp = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { mutate } = useMutation(signUp)

  const onFinish = async (values: LoginInfo) => {
    try {
      await mutate(values, {
        onSuccess: response => {
          message.success(
            '회원 가입이 완료되었습니다. 로그인 페이지로 이동합니다.'
          )
          history.replace('/login')
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Layout className="site-layout h-screen">
      <Header className="site-layout-header mb-6">
        <div className="header-wrapper mx-auto xs:w-full text-center">
          <span className="font-extrabold text-xl text-black">Coupas</span>
        </div>
      </Header>
      <Content>
        <div className="login-form-wrapper">
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}>
            <Form.Item
              label="아이디"
              requiredMark="optional"
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: '올바른 E-mail 을 입력해주세요.'
                }
              ]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="abc@gmail.com"
              />
            </Form.Item>
            {/* // Password RegExp: https://gist.github.com/ravibharathii/3975295 */}
            <Form.Item
              label="패스워드"
              requiredMark="optional"
              name="password"
              rules={[
                {
                  required: true,
                  pattern: RegExp(
                    '(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$'
                  ),
                  message:
                    '패스워드는 8자 이상, 대소문자, 특수문자 각각 1자 이상을 포함해야 합니다.'
                }
              ]}>
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button size="large" type="primary" htmlType="submit" block>
                회원 가입
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default SignUp

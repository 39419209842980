import React from 'react'
import RecentProducts from './RecentProducts'

const Home = () => {
  return (
    <div>
      <h2>최근 포스팅된 상품들</h2>
      <RecentProducts />
    </div>
  )
}

export default Home

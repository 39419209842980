import React, { FunctionComponent } from 'react'
import { ProductItemProps } from '../types/coupang'
import { Space, Typography } from 'antd'
import { numberWithCommas } from '../utils/stringUtils'
import ShippingIcon from './ShippingIcon'

const PriceInfo: FunctionComponent<ProductItemProps> = ({ item }) => {
  return (
    <Space size="small">
      <Typography.Text className="text-sm">
        {numberWithCommas(item.productPrice)}원
      </Typography.Text>
      <ShippingIcon item={item} />
    </Space>
  )
}

export default PriceInfo
import React from 'react'
import { Button, Divider, message, Popconfirm, Table, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useFetchGet } from '../../hooks/useFetchGet'
import Loading from '../../components/Loading'
import { deleteTemplate } from '../../api/templateApi'
import PageHeader from '../../components/PageHeader'
import { QueryResponse } from '../../types/common'
import './index.css'

const TemplateList = () => {
  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'templateId',
    //   width: 20
    // },
    {
      title: '제목',
      dataIndex: 'name',
      key: 'templateName',
      render: (text: string, record: any) => {
        return <Link to={`/templates/${record.id}`}>{record.name}</Link>
      }
    },
    {
      title: '액션',
      dataIndex: 'operation',
      width: 120,
      render: (text: string, record: any) => (
        <Popconfirm title="정말 삭제하시겠습니까?" onConfirm={handleDelete(record.id)}>
          <Button type="link">삭제</Button>
        </Popconfirm>
      )
    }
  ]

  const { mutate } = useMutation<QueryResponse, undefined, number>(deleteTemplate)
  const { data, isFetching, refetch } = useFetchGet('templates', '/templates')

  const handleDelete = (id: number) => async () => {
    try {
      await mutate(id, {
        onSuccess: () => {
          message.success('템플릿 삭제 완료')
          refetch()
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <PageHeader
        title="내 템플릿"
        buttonGroup={
          <Link key="register" to="/templates/new">
            <Button type="primary">등록</Button>
          </Link>
        }
        description="자주 사용하는 문구를 템플릿으로 등록하고 포스팅시에 재사용할 수 있습니다."
      />
      <Divider />
      <div>
        {isFetching ? (
          <Loading tipText="템플릿 목록을 불러오는 중입니다." />
        ) : (
          <Table columns={columns} dataSource={data?.result} rowKey="id" bordered={true} />
        )}
      </div>
    </>
  )
}

export default TemplateList

import React, {FunctionComponent} from 'react'
import './App.css'
import {Redirect, Route, Switch} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import Login from './Login'
import AuthenticationWrapper from './AuthenticationWrapper'
import {Error, Error403, Error404} from './components/Errors'
import {getAuthToken} from './utils/authUtils'
import {AppContextProvider} from './AppContextProvider'
import SetCoupangKey from './SetCoupangKey'
import SignUp from './SignUp'
import {ConfigProvider} from 'antd'

interface AuthenticationRouteProps {
  path: string
  wrapper: FunctionComponent
}

function AuthenticationRoute({ path, wrapper: AuthenticationWrapper }: AuthenticationRouteProps) {
  const accessToken = getAuthToken()
  const isAuthenticated = accessToken ? true : false

  return (
    <Route
      path={path}
      render={({ location }) =>
        isAuthenticated ? (
          <AuthenticationWrapper />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      useErrorBoundary: true,
      refetchOnWindowFocus: false
    },
    mutations: {
      useErrorBoundary: true
    }
  }
})

function App() {
  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{ token: { colorPrimary: '#1677ff', borderRadius: 4 } }}>
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signup/coupang" component={SetCoupangKey} />
              <Route exact path="/forbidden" component={Error403} />
              <Route exact path="/notFound" component={Error404} />
              <Route exact path="/serverError" component={Error} />

              <AuthenticationRoute path="/" wrapper={AuthenticationWrapper} />
            </Switch>
          </div>
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppContextProvider>
  )
}

export default App

import { CoupangProduct } from './types/coupang'
import { UserAuth } from './types/user'
import { Basket } from './types/basket'
import { SearchState } from './types/search'

export interface Action {
  type: string
  payload: any
}

export interface AppState {
  user: UserAuth
  products: CoupangProduct[]
  basket: Basket
  search: SearchState
}

const initialState: AppState = {
  user: {
    id: -1,
    email: ''
  },
  products: [],
  basket: {
    title: '',
    headerMessage: '',
    products: [],
    footerMessage: ''
  },
  search: {
    query: '',
    result: []
  }
}

function reducer(state: AppState, action: Action) {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        user: action.payload
      }
    case 'SET_BASKET':
      return {
        ...state,
        basket: action.payload
      }
    case 'ADD_PRODUCT_IN_BASKET':
      return {
        ...state,
        basket: {
          ...state.basket,
          products: state.basket.products.concat(action.payload)
        }
      }
    case 'DELETE_PRODUCT_IN_BASKET':
      return {
        ...state,
        basket: {
          ...state.basket,
          products: state.basket.products.filter(product => product.id !== action.payload.id)
        }
      }
    case 'SET_HEADER_MESSAGE_IN_BASKET':
      return {
        ...state,
        headerMessage: action.payload
      }
    case 'SET_FOOTER_MESSAGE_IN_BASKET':
      return {
        ...state,
        footerMessage: action.payload
      }
    case 'SET_SEARCH_RESULT':
      return {
        ...state,
        search: {
          query: action.payload.query,
          result: action.payload.result
        }
      }
    default:
      return state
  }
}

export { reducer, initialState }

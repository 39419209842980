import React, { FunctionComponent, useEffect, useState } from 'react'
import { Button, Checkbox, Divider, Form, Input, message, Space } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { useMutation } from 'react-query'
import { CoupangProduct } from '../../types/coupang'
import { Channel } from '../../types/channel'
import Loading from '../../components/Loading'
import { postTistory } from '../../api/postApi'
import { PostRequest } from '../../types/post'
import PageHeader from '../../components/PageHeader'
import { buildFinalHtml } from '../../utils/singlePostHtml'
import './single_post.css'
import { QueryResponse } from '../../types/common'
import { useChannelList } from '../../hooks/queries/channel'

const { TextArea } = Input

const SinglePost: FunctionComponent<RouteComponentProps> = ({ location }: any) => {
  const {
    state: { coupangData }
  } = location
  const product = coupangData as CoupangProduct

  const [html, setHtml] = useState<string>('')
  const [form] = Form.useForm()

  const { data: channelData, isFetching: isChannelFetching } = useChannelList()
  const { mutate } = useMutation<QueryResponse, unknown, PostRequest>(postTistory)

  const handlePost = async ({
    title,
    content,
    channelIds
  }: {
    title: string
    content: string
    channelIds: [number]
  }) => {
    const selectedChannels = channelData?.result?.filter((channel: Channel) => channelIds.includes(channel.id))

    for (const channel of selectedChannels) {
      const postRequest: PostRequest = {
        channelId: channel.id,
        title: title,
        content: content,
        products: [product]
      }

      try {
        await mutate(postRequest, {
          onSuccess: () => {
            message.success('포스팅 완료')
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    const html = buildFinalHtml('', '', product, '')
    setHtml(html)
    form.setFieldValue('content', html)
  }, [product])

  return (
    <div>
      <PageHeader title="포스팅" description="쿠팡 상품을 포스팅합니다." />
      <Divider />

      <Form form={form} name="post" onFinish={handlePost}>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: '제목을 입력하세요.'
            }
          ]}>
          <Input placeholder="제목을 입력하세요." />
        </Form.Item>

        <div className="coupang_content p-4 bg-white" dangerouslySetInnerHTML={{ __html: html }} />
        <Form.Item name="content" hidden={true}>
          <TextArea />
        </Form.Item>

        <Divider />
        <h3>채널</h3>
        {isChannelFetching && <Loading tipText="채널을 불러오는 중입니다." />}
        {!isChannelFetching && (
          <Form.Item
            name="channelIds"
            rules={[{ required: true, message: '최소 1개의 채널을 선택해주세요.', type: 'array' }]}>
            <Checkbox.Group>
              <Space direction="vertical">
                {channelData?.result.map((channel: Channel) => (
                  <Checkbox value={channel.id} checked={true} key={channel.id}>
                    {channel.name} ({channel.type})
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        )}
        <Divider />
        <Button type="primary" block={true} htmlType="submit">
          포스팅
        </Button>
        <Divider />
      </Form>
    </div>
  )
}

export default SinglePost

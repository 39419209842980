import { CoupangProduct } from '../types/coupang'
import { numberWithCommas } from './stringUtils'

function productsToHtml(products: CoupangProduct[], subId: string) {
  // prettier-ignore
  let productList = products
    .map((product: CoupangProduct, i: number) => {
      const productUrl = `${product.productUrl}&subId=${subId}`

      return `<li>
  <a href="${productUrl}" rel="noopener" target="_blank">
    <div class="item">
      <div class="ranking">${i + 1}</div>
      <div class="item-image">
        <img src="${product.productImage}" />
      </div>
      <div class="rocket-image">
        <img class="${product.isRocket ? '' : ' hidden'}" src="//image10.coupangcdn.com/image/badges/rocket/rocket_logo.png" alt="로켓배송">
      </div>
      <div class="item-name">${product.productName}</div>
      <div class="item-price">${numberWithCommas(product.productPrice)}원</div>
    </div>
  </a>
</li>`
}).join('')

  const ul = `
<div class="p_cont">
  <ul>
    ${productList}
  <ul>
</div>
`

  return ul
}

function messageToHtml(message: string) {
  if (!message) return ''
  const paragraphs = message
    .split('\n')
    .map(line => `<p>${line}</p>`)
    .join('')

  return `<div class="message">${paragraphs}</div>`
}

function partnersMessage() {
  return `<div class="partners_message"><p>이 포스팅은 쿠팡 파트너스 활동으로, 일정액의 수수료를 제공받고 있습니다.</p></div>`
}

function buildFinalHtml(headerMessage: string, footerMessage: string, products: CoupangProduct[], subId: string) {
  const headerMessageHtml = messageToHtml(headerMessage)
  const footerMessageHtml = messageToHtml(footerMessage)
  const productsHtml = productsToHtml(products, subId)
  const partnersMessageHtml = partnersMessage()
  const contentHtml = [headerMessageHtml, productsHtml, footerMessageHtml, partnersMessageHtml].join('')
  return `<div id="cps_container">${contentHtml}</div>`
}

export { productsToHtml, messageToHtml, partnersMessage, buildFinalHtml }

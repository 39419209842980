import React from 'react'
import { useFetchGet } from '../../hooks/useFetchGet'
import CoupangResult from './CoupangResult'

const GoldBox = () => {
  const { data, isFetching, isError } = useFetchGet('goldbox', '/coupang/goldbox')

  return (
    <div>
      <CoupangResult isFetching={isFetching} isError={isError} result={data?.result} />
    </div>
  )
}

export default GoldBox

import { fetchDelete, fetchGetData, fetchPost, fetchPut } from '../utils/fetchUtils'
import { Template } from '../types/template'
import { QueryResponse } from '../types/common'

function getTemplates(): Promise<QueryResponse> {
  return fetchGetData(`/templates`)
}

function getTemplate(id: number): Promise<QueryResponse> {
  return fetchGetData(`/templates/${id}`)
}

// create or modify
function postTemplate(template: Template): Promise<QueryResponse> {
  return fetchPost(`/templates`, template)
}

function modifyTemplate(template: Template): Promise<QueryResponse> {
  return fetchPut(`/templates/${template.id}`, template)
}

function deleteTemplate(id: number) {
  return fetchDelete(`/templates/${id}`)
}

export { getTemplates, getTemplate, postTemplate, modifyTemplate, deleteTemplate }

import React, { FunctionComponent, useMemo, useState } from 'react'
import locale from 'antd/es/date-picker/locale/ko_KR'
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title as ChartjsTitle,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import dayjs, { Dayjs } from 'dayjs'
import { Typography, Table, Divider } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFetchGet } from '../../hooks/useFetchGet'
import usePrevious from '../../hooks/usePrevious'
import PageHeader from '../../components/PageHeader'
import DatePicker from '../../components/DatePicker'
import { ReportClick, ReportCommission } from '../../types/coupang'
import { ColumnsType } from 'antd/es/table'

const { RangePicker } = DatePicker
const { Title } = Typography

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, ChartjsTitle, Tooltip, Legend)

const getChartData = (checked: CheckboxValueType[], clickData?: [ReportClick], commissionData?: [ReportCommission]) => {
  const labels = clickData?.map((item: ReportClick) => item.date) ?? []
  const clicks = clickData?.map((item: ReportClick) => item.click) ?? []
  const commissions = commissionData?.map((item: ReportCommission) => item.commission) ?? []
  const showClick = checked.some(type => type === 'click')
  const showCommission = checked.some(type => type === 'commission')
  let datasets = []

  if (showClick) {
    datasets.push({
      label: '클릭',
      backgroundColor: 'rgb(14, 157, 0)',
      borderColor: 'rgb(14, 157, 0)',
      data: clicks,
      yAxisID: 'y1'
    })
  }

  if (showCommission) {
    datasets.push({
      label: '수익',
      backgroundColor: 'rgb(255, 87, 51)',
      borderColor: 'rgb(255, 87, 51)',
      data: commissions,
      yAxisID: 'y2'
    })
  }

  return {
    labels: labels,
    datasets: datasets
  }
}

const chartOption = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  layout: {
    padding: 20
  },
  scales: {
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const
    },
    y2: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false
      }
    }
  },
  plugins: {
    legend: {
      position: 'top' as const
    },
    interaction: {
      intersect: false
    },
    tooltip: {
      enabled: true,
      intersect: true
    }
  }
}

const options = [
  { label: '클릭', value: 'click' },
  { label: '수익', value: 'commission' }
]

type RangeValue = [Dayjs | null, Dayjs | null] | null

interface TableData {
  key: string
  date: string
  click: number
  commission: number
}

const Report: FunctionComponent = () => {
  const now = dayjs()
  const [checked, setChecked] = useState<CheckboxValueType[]>(['click', 'commission'])
  const [datesValue, setDatesValue] = useState<RangeValue>([now.subtract(14, 'day'), now])
  const [datesRange, setDatesRange] = useState<RangeValue>(null)
  const startDate = datesValue == null ? now : datesValue[0]
  const endDate = datesValue == null ? now.subtract(14, 'day') : datesValue[1]
  const startDateStr = startDate?.format('YYYYMMDD')
  const endDateStr = endDate?.format('YYYYMMDD')
  const previousStartDateStr = usePrevious<string | undefined>(startDateStr)
  const previousEndDateStr = usePrevious<string | undefined>(endDateStr)

  const reloadReport =
    startDateStr != null &&
    endDateStr != null &&
    (startDateStr !== previousStartDateStr || endDateStr !== previousEndDateStr)

  const { data: clickData, isFetched: isClickDataFetched } = useFetchGet(
    'reportClicks',
    `/coupang/reports/clicks?startDate=${startDateStr}&endDate=${endDateStr}`,
    {
      enabled: reloadReport && checked.some(item => item === 'click')
    }
  )

  const { data: commissionData, isFetched: isCommissionDataFetched } = useFetchGet(
    'reportCommission',
    `/coupang/reports/commission?startDate=${startDateStr}&endDate=${endDateStr}`,
    {
      enabled: reloadReport && checked.some(item => item === 'commission')
    }
  )

  const disabledDate = (current: Dayjs) => {
    if (!datesRange) {
      return current.isAfter(now)
    }
    const isDisabled = datesRange[0] && (current.diff(datesRange[0], 'days') > 30 || current.isAfter(now))
    return !!isDisabled
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDatesRange(null)
      setDatesValue(null)
    }
  }

  const chartData = useMemo(
    () => getChartData(checked, clickData?.result, commissionData?.result),
    [clickData?.result, commissionData?.result, checked]
  )

  const tableData = useMemo(() => {
    if (!clickData?.result || !commissionData?.result) {
      return []
    }

    const commissionByDateMap = commissionData?.result.reduce((acc: any, cur: ReportCommission) => {
      acc[cur.date] = cur.commission
      return acc
    }, {})

    const reportClicks = clickData?.result as [ReportClick]

    return reportClicks
      .map(
        (rc: ReportClick): TableData => ({
          key: rc.date,
          date: rc.date,
          click: rc.click,
          commission: commissionByDateMap[rc.date]
        })
      )
      .sort((a: TableData, b: TableData) => (a.date > b.date ? -1 : 1))
  }, [clickData?.result, commissionData?.result])

  const onReportCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setChecked(checkedValues)
  }

  const columns: ColumnsType<TableData> = [
    {
      title: '날짜',
      dataIndex: 'date',
      key: 'date',
      render: date => <span>{date}</span>
    },
    {
      title: '클릭',
      dataIndex: 'click',
      key: 'click',
      render: click => <span>{click}</span>
    },
    {
      title: '수익',
      dataIndex: 'commission',
      key: 'commission',
      render: commission => <span>{commission}</span>
    }
  ]

  return (
    <>
      <PageHeader title="리포트" description="쿠팡 파트너스 활동을 통한 클릭수, 수익 지표를 확인합니다." />
      <Divider />
      <div>
        <div className="mb-1">
          <RangePicker
            locale={locale}
            value={datesValue}
            disabledDate={disabledDate}
            defaultValue={datesRange}
            onCalendarChange={val => setDatesRange(val)}
            onChange={val => setDatesValue(val)}
            onOpenChange={onOpenChange}
          />
        </div>
        <Line data={chartData} options={chartOption} className="bg-white mb-1" height={180} />
        {/*<div className="flex justify-center">*/}
        {/*  <Checkbox.Group options={options} defaultValue={checked} onChange={onReportCheckboxChange} />*/}
        {/*</div>*/}
      </div>
      <Divider />
      <Title level={4}>상세내역</Title>
      <Table columns={columns} dataSource={tableData} bordered={true} />
    </>
  )
}

export default Report

import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Report from './Report'

const Reports: FC = () => {
  return (
    <Switch>
      <Route path="/reports" component={Report} />
    </Switch>
  )
}

export default Reports

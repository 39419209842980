import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import TemplateList from './TemplateList'
import TemplateDetail from './TemplateDetail'

const Templates: FC = () => {
  return (
    <Switch>
      <Route path="/templates/:templateId(\d+)" component={TemplateDetail} />
      <Route path="/templates/new" component={TemplateDetail} />
      <Route path="/templates" component={TemplateList} />
    </Switch>
  )
}

export default Templates

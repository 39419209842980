import React, { useEffect } from 'react'
import { Divider } from 'antd'
import { useFetchGet } from '../../hooks/useFetchGet'
import EmptyQuery from '../../components/EmptyQuery'
import CoupangResult from './CoupangResult'
import { useAppDispatch, useAppState } from '../../AppContextProvider'
import { useLocation } from 'react-router-dom'

interface RouteState {
  query: string
}

const SearchResult = () => {
  const { search } = useAppState()
  const { state } = useLocation<RouteState>()
  const dispatch = useAppDispatch()
  const query = state.query
  // const isFetching = false
  // const isFetched = false
  // const isError = false
  // const data = { result: { productData: [] } }

  const { data, isFetching, isError, isFetched } = useFetchGet(
    ['coupangSearch', query],
    `/coupang/search?query=${query}`,
    {
      enabled: query && query !== ''
    }
  )

  console.log('결과 쿼리: ' + state.query)

  useEffect(() => {
    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: { query: query, result: data?.result.productData }
    })
  }, [isFetched])

  return (
    <div>
      <small className="text-gray-500">키워드 검색 결과입니다. (1 시간당 최대 10번 검색 가능합니다.)</small>
      <Divider style={{ marginTop: '1em' }} />
      {!query && <EmptyQuery />}
      {query && <CoupangResult isFetching={isFetching} isError={isError} result={search.result} />}
    </div>
  )
}

export default SearchResult

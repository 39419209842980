import React, { useCallback, useRef, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Popconfirm, Space, Table } from 'antd'
import { createDndContext, DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import './index.css'
import { useAppDispatch, useAppState } from '../../AppContextProvider'
import { BasketCoupangProduct, CoupangProduct } from '../../types/coupang'
import TemplateSelector from '../../components/TemplateSelector'
import { Template } from '../../types/template'
import { templateToText } from '../../utils/templateUtils'
import PriceAndShipping from '../../components/PriceAndShipping'

const { TextArea } = Input

// RowProps reference: https://codesandbox.io/s/react-typescript-forked-ogj8r?file=/src/index.tsx:497-561
interface RowProps {
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
}

type MyRowProps = RowProps & React.HTMLAttributes<HTMLElement>

// TODO : sortable table - https://codesandbox.io/s/vh5xs?file=/index.js:304-314

const RNDContext = createDndContext(HTML5Backend)
const type = 'DraggableBodyRow'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: any) => {
  const ref = React.useRef()
  const [form] = Form.useForm()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: (item: any) => {
      moveRow(item.index, index)
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drop(drag(ref))

  return (
    <Form key={index} form={form} component={false}>
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    </Form>
  )
}

const BasketList = () => {
  const { basket } = useAppState()
  const dispatch = useAppDispatch()
  const [dataSource, setDataSource] = useState<BasketCoupangProduct[]>(basket.products)
  const [form] = Form.useForm()
  const history = useHistory()

  const [isVisibleTemplateModal, setVisibleTemplateModal] = useState(false)
  const [templateText, setTemplateText] = useState('')
  const [openModalName, setOpenModalName] = useState('')

  const columns = [
    {
      title: '상품 이미지',
      dataIndex: 'productImage',
      width: 100,
      key: 'productImage',
      render: (text: string, record: CoupangProduct) => {
        const { productName, productImage } = record
        return <img src={productImage} alt={productName} style={{ width: '64px', height: '64px' }} />
      }
    },
    {
      title: '상품 정보',
      dataIndex: 'productName',
      key: 'name',
      render: (text: string, record: CoupangProduct) => {
        const { productUrl, productName } = record
        return (
          <Space direction="vertical" size="small">
            <a href={productUrl} target="_blank" rel="noopener noreferrer">
              {productName}
            </a>
            <PriceAndShipping item={record} />
          </Space>
        )
      }
    },
    {
      title: '액션',
      width: 80,
      dataIndex: 'operation',
      render: (text: string, record: BasketCoupangProduct) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="정말 삭제하시겠습니까?" onConfirm={() => handleDelete(record.id)}>
            <Button type="link">삭제</Button>
          </Popconfirm>
        ) : null
    }
  ]

  const handleDelete = (id: string) => {
    setDataSource(dataSource.filter(item => item.id !== id))
    dispatch({ type: 'DELETE_PRODUCT_IN_BASKET', payload: { productId: id } })
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex]
      setDataSource(
        update(dataSource, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [dataSource]
  )

  const manager = useRef(RNDContext)

  const components = {
    body: {
      row: DraggableBodyRow
    }
  }

  const handlePreviewPost = () => {
    const { title, headerMessage, footerMessage } = form.getFieldsValue()

    const basketData = {
      title,
      headerMessage,
      products: dataSource,
      footerMessage
    }

    dispatch({ type: 'SET_BASKET', payload: basketData })
    history.push('/basket/post')
  }

  const toggleTemplateModal = (_openModalName: string) => () => {
    setVisibleTemplateModal(visible => !visible)
    setOpenModalName(_openModalName)
  }

  const handleClickTemplate = (template: Template) => {
    const _templateText = templateToText(template)
    setTemplateText(_templateText)
  }

  const handleSelectTemplate = () => {
    const type = openModalName === 'header' ? 'headerMessage' : 'footerMessage'
    form.setFieldsValue({ [type]: templateText })
    setVisibleTemplateModal(visible => !visible)
  }

  return (
    <>
      <div className="flex justify-between my-4 content-center items-center">
        <div>
          <h1 className="mb-0">상품 바구니</h1>
          <small className="text-gray-500">
            상품 바구니 입니다. 여러 상품을 담아두고 랭킹 형태로 포스팅 할 수 있습니다.
          </small>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            title: basket.title,
            headerMessage: basket.headerMessage,
            footerMessage: basket.footerMessage
          }}
          onFinish={handlePreviewPost}>
          <Form.Item
            name="title"
            label="포스팅 제목"
            rules={[
              {
                required: true,
                message: '포스팅 제목은 필수값 입니다.'
              }
            ]}>
            <Input placeholder="블로그 포스팅 제목을 이름을 입력해주세요." />
          </Form.Item>
          <Form.Item
            name="headerMessage"
            label={
              <div>
                상단 문구&nbsp;
                <Button onClick={toggleTemplateModal('header')} size="small">
                  템플릿 불러오기
                </Button>
              </div>
            }>
            <TextArea placeholder="상품목록 위에 들어갈 상단 문구를 입력해주세요." rows={3} />
          </Form.Item>
          <Form.Item extra="ⓘ 행을 드래그하여 추가된 상품의 순서를 바꿀 수 있습니다.">
            <DndProvider manager={manager.current.dragDropManager!}>
              <Table<BasketCoupangProduct>
                rowKey={product => `${product.productId}_${product.rank}`}
                pagination={false}
                bordered={true}
                columns={columns}
                components={components}
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                onRow={(record, index) =>
                  ({
                    index,
                    moveRow
                  } as MyRowProps)
                }
              />
            </DndProvider>
          </Form.Item>
          <Form.Item
            name="footerMessage"
            label={
              <div>
                하단 문구&nbsp;
                <Button onClick={toggleTemplateModal('footer')} size="small">
                  템플릿 불러오기
                </Button>
              </div>
            }>
            <TextArea placeholder="상품목록 아래에 들어갈 하단 문구를 입력해주세요." rows={3} />
          </Form.Item>
          <Form.Item>
            <Button key="registerTistory" type="primary" htmlType="submit">
              미리보기 & 포스팅
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Modal
        open={isVisibleTemplateModal}
        title="템플릿"
        width={1000}
        okText="확인"
        cancelText="닫기"
        onOk={handleSelectTemplate}
        onCancel={toggleTemplateModal('')}>
        <TemplateSelector handleClickTemplate={handleClickTemplate} />
      </Modal>
    </>
  )
}

export default BasketList

import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { Layout, Modal, Space } from 'antd'

import { useAppDispatch, useAppState } from './AppContextProvider'
import Channel from './containers/Channel'
import SinglePost from './containers/Product/SinglePost'
import TemplateList from './containers/Templates'
import Settings from './containers/Settings'
import Basket from './containers/Basket'
import SearchInput from './components/SearchInput'
import SearchResult from './containers/Product/SearchResult'
import DropdownMenuButton from './components/DropdownMenuButton'
import Home from './containers/Home'
import Reports from './containers/Reports'
import { useMyAccount } from './hooks/queries/account'
import CoupangRecommend from './containers/CoupangRecommend'

const { Header, Content, Footer } = Layout

const AuthenticationWrapper: FunctionComponent = () => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleSearch = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setSearchOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }

  return (
    <Layout className="site-layout" style={{ minHeight: '100vh' }}>
      <Header className="site-layout-header">
        <div className="header-wrapper mx-auto xs:w-full flex">
          <div className="flex-none">
            <Link to={'/'}>
              <span className="font-extrabold text-xl text-black">Coupas</span>
            </Link>
          </div>
          <div className="flex-initial mx-4">
            <SearchInput />
          </div>
          <div className="flex-auto"></div>
          <div className="flex-none">
            <DropdownMenuButton />
          </div>
        </div>
      </Header>
      <Content className="site-layout-content md:px-20">
        <div className="content-wrapper">
          <Switch>
            <Route path="/channels" component={Channel} />
            <Route path="/post" component={SinglePost} />
            <Route path="/templates" component={TemplateList} />
            <Route path="/basket" component={Basket} />
            <Route path="/coupang_recommend" component={CoupangRecommend} />
            <Route path="/reports" component={Reports} />
            <Route path="/settings" component={Settings} />
            <Route path="/search" component={SearchResult} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
        <Modal
          title="검색"
          open={searchOpen}
          onOk={handleSearch}
          confirmLoading={confirmLoading}
          onCancel={() => setSearchOpen(false)}
          centered={true}>
          <SearchInput />
        </Modal>
      </Content>
      <Footer className="site-layout-footer">
        <div className="footer-wrapper mx-auto xs:w-full flex justify-between items-center">
          <div className="flex-none">
            <span>© Coupas</span>
          </div>
          <Space>
            <span className="text-xs">이용약관</span>
            <span className="text-xs">개인정보 취급방침</span>
            <span className="text-xs">사업자 정보확인</span>
          </Space>
        </div>
      </Footer>
    </Layout>
  )
}

export default AuthenticationWrapper

import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import BasketList from './BasketList'
import BasketPosting from './BasketPosting'

const Basket: FC = () => {
  return (
    <Switch>
      <Route path="/basket/post" component={BasketPosting} />
      <Route path="/basket" component={BasketList} />
    </Switch>
  )
}

export default Basket

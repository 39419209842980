import { notification } from 'antd'

const successNotification = (message: string, description?: string) => {
  notification.success({
    message,
    description,
    placement: 'bottomRight'
  })
}

const errorNotification = (message: string, description?: string) => {
  notification.error({
    message,
    description,
    placement: 'bottomRight'
  })
}

const infoNotification = (message: string, description?: string) => {
  notification.info({
    message,
    description,
    placement: 'bottomRight'
  })
}

const warnNotification = (message: string, description?: string) => {
  notification.warning({
    message,
    description,
    placement: 'bottomRight'
  })
}

export { successNotification, errorNotification, infoNotification, warnNotification }

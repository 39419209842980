import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Input, message, Radio } from 'antd'
import { useMutation } from 'react-query'
import { createChannel, modifyChannel } from '../../api/channelApi'
import { ChannelCreateRequest } from '../../types/channel'
import Loading from '../../components/Loading'
import PageHeader from '../../components/PageHeader'
import { useChannel } from '../../hooks/queries/channel'
import { QueryResponse } from '../../types/common'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

interface RouteParams {
  channelId: string
}

const ChannelCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()
  const { channelId } = useParams<RouteParams>()

  const isModify = channelId !== undefined
  const registerText = isModify ? '수정' : '생성'

  const { data, isFetching } = useChannel(channelId, {
    enabled: isModify
  })
  const { result } = data || {}
  const mutation = useMutation<QueryResponse, unknown, ChannelCreateRequest>(isModify ? modifyChannel : createChannel)

  const handleSubmit = async (values: any) => {
    try {
      await mutation.mutate(
        {
          id: channelId,
          userId: 1,
          ...form.getFieldsValue()
        },
        {
          onSuccess: () => {
            message.success(`채널 ${registerText} 완료`)
            history.replace('/channels')
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <PageHeader title={`채널 ${registerText}`} description={`채널을 ${registerText} 합니다.`} />
      <div>
        {isFetching ? (
          <Loading tipText="채널 목록을 불러오는 중입니다." />
        ) : (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              type: result?.type || 'NAVER',
              name: result?.name,
              description: result?.description,
              createdAt: result?.createdAt,
              updatedAt: result?.updatedAt
            }}>
            <Form.Item name="type" label="채널 타입">
              <Radio.Group>
                <Radio style={radioStyle} value="NAVER">
                  네이버
                </Radio>
                <Radio style={radioStyle} value="TISTORY">
                  티스토리
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="name" label="채널이름">
              <Input placeholder="채널이름을 입력하세요." />
            </Form.Item>
            <Form.Item name="description" label="설명">
              <Input placeholder="설명을 입력하세요." />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {registerText}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  )
}

export default ChannelCreate

import React, { FunctionComponent, ReactChild } from 'react'

interface LoadingFailProps {
  failMessage?: ReactChild
}

const LoadingFail: FunctionComponent<LoadingFailProps> = ({ failMessage }) => {
  return (
    <div className="text-center p-20">
      <span>{failMessage}</span>
    </div>
  )
}

export default LoadingFail

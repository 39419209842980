import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import ChannelList from './ChannelList'
import ChannelCreate from './ChannelCreate'
import TistoryAdd from './TistoryAdd'

const Channel: FC = () => {
  return (
    <Switch>
      <Route path="/channels/:channelId(\d+)" component={ChannelCreate} />
      <Route path="/channels/create" component={ChannelCreate} />
      <Route path="/channels/tistory" component={TistoryAdd} />
      <Route path="/channels" component={ChannelList} />
    </Switch>
  )
}

export default Channel
